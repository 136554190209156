/**
 * @param {String} apiModel Name of the url part that identifies the model.
 */
export default function crud(apiModel) {
    return {
        mounted() {
            this.resetModel();
        },
        data() {
            return {
                loading: false,
                show_new_model: false,
                loading_new_model: false,
                loading_models: [],
                new_model: {},
                products: []
            }
        },
        methods: {
            resetModel()
            {
                // Set from child.
                // Should update `new_model` with default data.
            },
            /**
             * @param {Object} new_model
             * @return {Promise}
             */
            addModel(new_model) {
                this.loading_new_model = true;
                return this.$http.post(apiModel, new_model)
                    .then((response) => {
                        this.$emit('created', response.data);
                        // Reset object because it has been saved.
                        this.resetModel();
                        this.loading_new_model = false;
                        this.show_new_model = false;
                    }).catch(() => {
                        this.loading_new_model = false;
                    });
            },
            /**
             * @param {Object} model
             * @return {Promise}
             */
            updateModel(model) {
                this.$set(model, '_loading', true);
                return this.$http.put(apiModel + `/${model.id}`, model)
                    .then((response) => {
                        this.$emit('updated', response.data);
                        this.$set(model, '_loading', false);
                        this.$set(model, '_editting', false);
                        order_line._editting = false;
                    }).catch(() => {
                        this.$set(model, '_loading', false);
                    });
            },
            /**
             * @param {Object} model
             * @param {Number} index
             * @return {Promise}
             */
            deleteModel(model, index) {
                if(confirm(this.$t('notifications.confirm_deletion', { object: this.$t('crud.items._singular') }))) {
                    this.$set(this.loading_models, index, true);
                    return this.$http.delete(apiModel + `/${model.id}`).then(() => {
                        this.$emit('deleted', model);
                        this.$set(this.loading_models, index, false);
                    }).catch(() => {
                        this.$set(this.loading_models, index, false);
                    });
                }
                return Promise.reject();
            },
        }
    }
}
