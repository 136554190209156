<template>
    <loading v-if="!products"></loading>
    <div class="table-responsive" v-else>
        <table class="table table-sm">
            <tr>
                <th>{{ $t('crud.prices.product') }}</th>
                <th>{{ $t('crud.prices.min_quantity') }}</th>
                <th>{{ $t('crud.prices.price') }}</th>
                <th style="width: 1px;" class="text-right">
                    <button @click="show_new_model = !show_new_model"
                            class="btn btn-sm btn-outline-secondary"><i class="fa fa-plus"></i>
                    </button>
                </th>
            </tr>
            <transition name="fade">
                <tr v-if="show_new_model">
                    <td>
                        <form id="add-price-form" @submit.prevent="_addModel(new_model)"></form>
                        <b-form-select size="sm" :options="products" v-model="new_model.product_id" text-field="name" value-field="id" required form="add-price-form">
                            <template slot="first">
                                <option :value="null" disabled>{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </td>
                    <td>
                        <b-form-input size="sm" v-model="new_model.min_quantity" form="add-price-form"></b-form-input>
                    </td>
                    <td>
                        <b-input-group size="sm" prepend="€">
                            <b-form-input v-model="new_model.price" required form="add-price-form" @keypress.native="onlyNumber"></b-form-input>
                        </b-input-group>
                    </td>
                    <td class="text-right">
                        <loading-button class="btn btn-sm btn-outline-secondary"
                                        form="add-price-form"
                                        type="submit"
                                        v-b-tooltip
                                        :title="$t('crud.create')"
                                        :loading="loading_new_model">
                            <i class="fa fa-save"></i>
                        </loading-button>
                    </td>
                </tr>
            </transition>
            <tr v-for="(model, index) in models">
                <template v-if="!model._editting">
                    <td>
                        <router-link :to="{ name: 'products.show', params: { id: model.product_id } }" target="_blank" :title="$t('crud.view')">
                            {{ model.product.name }}
                        </router-link>
                    </td>
                    <td>
                        {{ model.min_quantity }}
                    </td>
                    <td>
                        {{ model.price | toCurrency }}
                    </td>
                    <td class="text-right">
                        <div class="btn btn-group btn-group-sm p-0">
                            <loading-button v-if="model._editting"
                                    :loading="model._loading"
                                    class="btn btn-sm btn-outline-secondary"
                                    type="submit"

                                    :title="$t('crud.save')"
                                    :form="'update-price-form-' + model.id"><i class="fa fa-save"></i></loading-button>
                            <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!model._editting"
                                    @click="$set(model, '_editting', true)"
                                    :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                            <loading-button class="btn btn-sm btn-outline-danger"
                                    :loading="loading_models[index]"
                                    @click="_deleteModel(model, index)"
                                    :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                        </div>
                    </td>
                </template>
                <template v-else>
                    <td>
                        <form :id="'update-price-form-' + model.id" @submit.prevent="updateModel(model)"></form>
                        <b-form-select size="sm" :options="products" v-model="model.product_id" text-field="name" value-field="id" required form="add-price-form">
                            <template slot="first">
                                <option :value="null" disabled>{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </td>
                    <td>
                        <b-form-input size="sm" v-model="model.min_quantity" form="add-price-form"></b-form-input>
                    </td>
                    <td>
                        <b-input-group size="sm" prepend="€">
                            <b-form-input v-model="model.price" required form="add-price-form" @keypress.native="onlyNumber"></b-form-input>
                        </b-input-group>
                    </td>
                    <td class="text-right">
                        <div class="btn btn-group btn-group-sm p-0">
                            <loading-button v-if="model._editting"
                                            :loading="model._loading"
                                            class="btn btn-sm btn-outline-secondary"
                                            type="submit"

                                            :title="$t('crud.save')"
                                            :form="'update-price-form-' + model.id"><i class="fa fa-save"></i></loading-button>
                            <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!model._editting"
                                    @click="$set(model, '_editting', true)"
                                    :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                            <loading-button class="btn btn-sm btn-outline-danger"
                                            :loading="loading_models[index]"
                                            @click="_deleteModel(model, index)"
                                            :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                        </div>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script>
    import crud from '../crud/mixin_table_crud';
    export default {
        props: ['record', 'apiModel'],
        mixins: [crud('prices')],
        mounted() {
            this.getProducts();
        },
        data() {
            return {
                new_model: {},
                models: this.record.prices
            }
        },
        methods: {
            resetModel() {
                this.new_model = Object.assign({}, {
                    product_id: undefined,
                    quantity: undefined,
                    price: undefined,
                });
            },
            _addModel(new_model) {
                this.loading_new_model = true;
                return this.$http.post(`/${this.apiModel}/${this.record.id}/prices`, new_model)
                    .then((response) => {
                        new_model.product = response.data.product;
                        this.$emit('created', response.data);
                        // Reset object because it has been saved.
                        this.resetModel();
                        this.loading_new_model = false;
                        this.show_new_model = false;
                        this.models.push(new_model);
                    }).catch(() => {
                        this.loading_new_model = false;
                    });
            },
            _deleteModel(model, index) {
                return this.deleteModel(model, index).then(() => {
                    this.models.splice(index, 1);
                })
            },
            /**
             * @return {Promise}
             */
            getProducts() {
                return this.$http.get('/products').then((response) => {
                    this.products = response.data;
                });
            }
        }
    }
</script>
