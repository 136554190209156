<template>
    <div>
        <loading-card :loading="!loaded">
            <div class="card-header">{{ $t('crud.zones._show') }}</div>
            <div class="card-body">
                <form-component @submit="updateFromRoute" :record="record"></form-component>
            </div>
        </loading-card>

        <div class="card" v-if="loaded">
            <div class="card-header">{{ $t('crud.zone_regions._plural') }}</div>
            <div class="card-body">
                <zone-regions-component :record="record" type="transporter_zones" @created="load" @deleted="load"></zone-regions-component>
            </div>
        </div>
    </div>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import FormComponent from './Form';
    import PricesComponent from './../_shared/Prices';
    import ZoneRegionsComponent from './../_shared/ZoneRegions';

    export default {
        mixins: [functions],
        components: {FormComponent, PricesComponent, ZoneRegionsComponent},
        data() {
            return {
                crud: config('transporter_zones')
            };
        }
    }
</script>
